@media (max-width: 450px) {
    .user_select {
        margin-top: 10px;
    }
    .for-phone {
        display: inline-block!important;
    }
    .for-pc {
        display: none;
    }

    .for-phone tbody tr td {
        width: 100%;
    }

    .dtr-details {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .child {
        width: 100%;
    }
    
    .child tr td:last-child {
        border-right-width: 1px!important;
    }
    
    .dtr-details li {
        padding: 0.5rem 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #eee;
    }
    
    .dtr-details li:last-child {
        border-bottom: none;
    }
    
    .dtr-details .dtr-title {
        display: inline-block;
        min-width: 8rem;
        font-weight: 500;
        color: #424242;
    }
}

@media (max-width: 768px) {
    .pagination-2 {
        margin-top: 10px;
    }
}

a {
    color: #444;
}

a:hover {
    color: #ef5a32;
}

.for-phone {
    display: none;
}

#content, button, input, select, textarea {
    font-size: 12px!important;
}


.topbar, button.dropdown-item, .copyright { 
    font-size: 14px!important;
}

.fa-search {
    right: 22px;
    margin-top: -22px;
    position: absolute;
}

.card-body {
    width: 100%;
}

.logo {
    width: 2rem;
}

.img-logo {
    margin-left: 8px;
    width: 100%;
}

.img_show_profile {
    width: 180px;
    height: 180px;
    margin-left: 140px;
    margin-bottom: 20px;
}

.thead {
    background-color: #00CB90;
    color: white
}

.tr-del {
    background-color: #595C69 !important;
    color: white
}

.tr-blue {
    background-color: #00CB90 !important;
    color: white
}

.btn-blue {
    background-color: #00CB90;
    color: white
}

.btn-blue:hover {
    background-color: #029e72;
    color: white;
}

.border_group {
    border: none!important;
}

a:hover {
    text-decoration: none;
}

.Toastify__toast-body {
    font-size: 14px;
}